import React, { useState, useEffect } from 'react';
import { Row } from "reactstrap";
import TreatmentCard from '../TreatmentCard'
import TipCard from '../TipCard';
import { handleRecommendations } from "../../components/GenerateRecommendations"
import { getRequest } from "../../API/ApiHandler"
import warning from '../../img/warning.png';
import FavouriteIcon from '../UI/Icons/FavouriteIcon'

const Recommendations = ({ favs, setFavs, favTips, setFavTips }) => {
  const [topThreeSC, setTopThreeSC] = useState()
  const [topThreeHCP, setTopThreeHCP] = useState()
  const [suggestedTips, setSuggestedTips] = useState()
  const [favourites, setFavourites] = useState()
  const [favouriteTips, setFavouriteTips] = useState()

  const getUserSC = async () => {
    let data = await getRequest(`/userScs/user`)
    if (data.length) {
      setTopThreeSC(data)

    } else {
      await handleRecommendations()
      getUserSC()
    }
  }

  const getUserHCP = async () => {
    let data = await getRequest(`/userHcps/user`)
    if (data.length) {
      setTopThreeHCP(data)
    } else {
      await handleRecommendations()
      getUserHCP()
    }
  }

  const getUserTips = async () => {
    let tips = await getRequest(`/userTips/user`)
    if (tips) {
      let { data } = tips
      // console.log(tips)
      setSuggestedTips(data)

    } else {
      await handleRecommendations()
      getUserTips()
    }
  }

  useEffect(() => {
    getUserSC()
    getUserHCP()
    getUserTips()
  }, [])

  useEffect(() => {
    if (favs) setFavourites(favs)
  }, [favs])

  useEffect(() => {
    if (favTips) setFavouriteTips(favTips)
  }, [favTips])

  const topThreeSCList = topThreeSC &&
    topThreeSC.map(treatment =>
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={favs}
        setSelected={setFavs}
        icon="fav"
      />
    )

  const topThreeHCPList = topThreeHCP &&
    topThreeHCP.map(treatment =>
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={favourites}
        setSelected={setFavs}
        icon="fav"
      />
    )

  const suggestedTipsList = suggestedTips &&
    suggestedTips.map(tip =>
      <TipCard
        key={tip.id}
        tip={tip}
        selected={favouriteTips}
        setSelected={setFavTips}
        icon="fav"
      />
    )

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const outline = { fill: '#FFFFFF', stroke: 'rgba(0, 0, 0, 1)', strokeMiterlimit: 10, strokeWidth: '8px' }

  return (
    <React.Fragment>
      <div className="notice">
        <h5 style={{ marginBottom: "25px" }}>
          {lang === "English" ?
            "Here is a list of suggested treatments you may like to try now or talk about with your health care team." :
            "Voici une liste de traitements que vous pourriez essayer dès maintenant ou dont vous pourriez parler avec votre équipe soignante."}
        </h5>
        <div>
          <h5>{lang === "English" ? "Each treatment is colour-coded to describe how recommended it is by experts:" : "Chaque traitement est codé par couleur pour décrire à quel point il est recommandé par les experts :"}</h5>
          <h5 style={{ display: 'flex', alignItems: 'center' }}><div style={{ height: "25px", width: "25px", background: 'green', marginRight: "10px", border: "1px black solid" }}></div>: {lang === "English" ? "Some evidence that the intervention is effective and safe and usually recommended by experts." : "Certaines preuves que le traitement est efficace et sûre et généralement recommandé par des experts."}</h5>
          <h5 style={{ display: 'flex' }}><div style={{ height: "25px", width: "25px", background: 'yellow', marginRight: "10px", border: "1px black solid" }}></div> : {lang === "English" ? "Uncertain evidence that the intervention is effective and safe, may sometimes be recommended by experts." : "Preuve incertaine que le traitement est efficace et sûre, peut parfois être recommandé par des experts."}</h5>
          <h5 style={{ display: 'flex', alignItems: 'center' }}><div style={{ height: "25px", width: "25px", background: 'red', marginRight: "10px", border: "1px black solid" }}></div> : {lang === "English" ? "Usually not recommended by experts because of potential side effects." : "Généralement déconseillé par les experts en raison des effets secondaires potentiels. "}</h5>
          <h5 style={{ marginTop: '25px' }}>{lang === "English" ? "The choice will depend on each person’s characteristics and context." : "Le choix dépendra des caractéristiques et du contexte de chaque personne."}</h5>
        </div>
      </div>
      <div className="review-content">
        {/*****************
         SC TREATMENTS
          ******************/}
        <div className="title-padding">
          <h4>{lang === "English" ? "Treatments you could try now" : "Traitements que vous pourriez essayer dès maintenant"}</h4>
        </div>
        <Row className="card-row">
          {topThreeSCList}
        </Row>
      </div>
      {/*****************
       HCP TREATMENTS
        ******************/}
      <div className="review-content">
        <div className="title-padding">
          <h4>{lang === "English" ?
            "Treatments you could talk about with your health care team before trying" :
            "Traitements dont vous pourriez parler avec votre équipe soignante avant de les essayer"}
          </h4>
        </div>
        <Row className="card-row">
          {topThreeHCPList}
        </Row>
      </div>

      {/*****************
       TIPS
        ******************/}
      <div className="review-content">
        <div className="title-padding">
          <h4>{lang === "English" ?
            "Activity tips you could try now" :
            "Trucs d'activité que vous pourriez suivre dès maintenant"}
          </h4>
        </div>
        <Row className="card-row">
          {suggestedTipsList}
        </Row>
      </div>


      <div className="notice review-description-div">
        <div className="disclaimer">
          <img src={warning} alt="warning" />
          <div>
            <p>
              {lang === "English" ?
                "Please consider the benefits, risks and how easy it is to use the treatments before making a selection. You can also discuss it with your health care team." :
                "N’oubliez pas de considérer les avantages, les risques et la facilité d’utilisation de ces traitements avant de faire un choix. Vous pouvez également en discuter avec votre équipe soignante."}
            </p>
            <p>
              {lang === "English" ?
                "Keep taking your prescribed treatments so that your arthritis does not get worse." :
                "Pour éviter que votre arthrite s’aggrave, continuez à suivre les traitements qui vous ont été prescrits."}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Recommendations