import React, { useEffect, useState } from "react";
import { NavLink as RRNav } from "react-router-dom";
import { Button } from "reactstrap";
import { getRequest } from "../../API/ApiHandler";
import { getUserType, logout } from "../../components/AuthenticationService";
import Footer from "../../components/Footer";
import Header from "../../components/Home/Header";
import IdleComponent from "../../components/IdleComponent";
import AllTreatments from "../../components/Review/AllTreatments";
import BottomTabNavReview from "../../components/Review/BottomTabNavReview";
import Favourites from "../../components/Review/Favourites";
import Recommendations from "../../components/Review/Recommendations";
import TabNavReview from "../../components/Review/TabNavReview";
import Subheader from "../../components/UI/Subheader/Subheader";
import "./ReviewAndSelect.css";

const ReviewAndSelect = (props) => {
  const [treatments, setTreatments] = useState([]);
  const [tips, setTips] = useState([]);
  const [favourites, setFavourites] = useState();
  const [favouriteTips, setFavouriteTips] = useState();
  const [navTo, setNavTo] = useState("");
  const [navBy, setNavBy] = useState("");
  const [saved, setSaved] = useState(true);
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const [didSelect, setDidSelect] = useState(false);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    getUserType();
  });

  const getUserFavourite = async () => {
    let data = await getRequest(`/userFavourites/user`);
    if (data) {
      const ids = data.map((treatment) => {
        return { id: treatment.id };
      });
      setFavourites(ids);
      console.log(
        "Review and Select - Get Treatements: " + JSON.stringify(ids)
      );
    }
  };

  const getUserFavouriteTip = async () => {
    let data = await getRequest(`/userFavouriteTips/user`);
    if (data) {
      const ids = data.map((tip) => {
        return { id: tip.id };
      });
      setFavouriteTips(ids);
      console.log("Review and Select - Get Tips: " + JSON.stringify(ids));
    }
  };

  const getTreatments = async () => {
    let data = await getRequest("/treatments");
    if (data.length > 0) setTreatments(data);
  };

  const getTips = async () => {
    let data = await getRequest("/tips");
    if (data.length > 0) setTips(data);
  };

  // const handleStepperNav = to => {
  //   props.history.push(to)
  // }

  const handleButtonNav = () => {
    if (saved) {
      props.history.push(`/make-your-plan`);
    } else {
      setNavBy("button");
      setDisplayNavDialog(true);
    }
  };

  const handleStepperNav = (to) => {
    if (saved) {
      props.history.push(to);
    } else {
      setNavBy("stepper");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`);
    } else {
      setNavBy("admin");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderProgressNav = () => {
    if (saved) {
      props.history.push(`/userPanel/average`);
    } else {
      setNavBy("user");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderLogout = () => {
    if (saved) {
      logout();
      props.history.push(`/`);
    } else {
      setNavBy("logout");
      setDisplayNavDialog(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTreatments();
    getTips();
    getUserFavourite();
    getUserFavouriteTip();
  }, []);

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  return (
    <div className="reviewDiv wrapper">
      <IdleComponent history={props.history} />
      <div className="page-header">
        <Header
          current={props}
          handleNav={handleStepperNav}
          setNavTo={setNavTo}
        />
      </div>
      <div className="page-subheader">
        <Subheader
          title={
            lang === "English"
              ? "Review and select treatment options"
              : "Réviser et sélectionner"
          }
          handleAdmin={handleSubHeaderAdminNav}
          handleUser={handleSubHeaderProgressNav}
          handleLogout={handleSubHeaderLogout}
        />
      </div>

      <div className="body-container mb-5">
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/whats-important-to-you">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <Button className="next-btn" tag={RRNav} to="/make-your-plan">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
        <div className="more-option-notice">
          <h5>
            {lang === "English" ? (
              <>
                Here are <b>“Suggested treatments and tips”</b> for you based on
                where you have symptoms, the types of treatments you prefer and
                what scientific studies say. Review treatments and click on
                “Read more” if you wish. Save those you prefer to your
                favourites by clicking on the &#10084;. <br />
                <br /> You can click on the <b>“All treatments and tips”</b> tab
                to see all treatments, and click on the <b>“My favourites”</b>
                 tab to review your favourites. You can also go back to Step 2
                to change your answers and see new treatments.
              </>
            ) : (
              <>
                Voici des <b>« Traitements et trucs suggérés »</b> en
                fonction de l'endroit où vous ressentez des symptômes, des types
                de traitements que vous préférez et de ce que disent les études
                scientifiques. Passez en revue les traitements et cliquez sur «
                Lire la suite » si vous souhaitez des informations sur chaque
                traitement. Enregistrez ceux que vous préférez dans vos favoris
                en cliquant sur le &#10084;.
                <br />
                <br /> Vous pouvez cliquer sur l'onglet 
                <b>« Tous les traitements et trucs »</b> pour voir tous les
                traitements, puis cliquer sur l'onglet <b>« Mes favoris »</b>
                 pour consulter vos favoris. Vous pouvez également revenir à
                l'étape 2 pour modifier vos réponses et voir de nouveaux
                traitements
              </>
            )}
          </h5>
        </div>

        <TabNavReview current={props} />
        <div className="review-box-container">
          {/* RECOMMENDATIONS COMPONENT */}
          {props.match.params.tab === "recommendations" && (
            <Recommendations
              favs={favourites}
              setFavs={setFavourites}
              favTips={favouriteTips}
              setFavTips={setFavouriteTips}
              treatments={treatments}
              tips={tips}
            />
          )}
          {/* ALL TREATMENTS COMPONENT */}
          {props.match.params.tab === "allTreatments" && (
            <AllTreatments
              favs={favourites}
              setFavs={setFavourites}
              treatments={treatments}
              favTips={favouriteTips}
              setFavTips={setFavouriteTips}
            />
          )}
          {/* FAVOURITES COMPONENT */}
          {props.match.params.tab === "favorites" && (
            <Favourites
              favs={favourites}
              setFavs={setFavourites}
              favTips={favouriteTips}
              setFavTips={setFavouriteTips}
              treatments={treatments}
              tips={tips}
            />
          )}
        </div>
        <BottomTabNavReview current={props} />
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/whats-important-to-you">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <Button className="next-btn" tag={RRNav} to="/make-your-plan">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
      </div>
      <Footer />
      {/* {!didSelect && <DialogBox description="The selected information will be saved in the trial database. You can modify the information as needed." step='s3Trial' />}
      <NavigationDialog open={displayNavDialog} handleClose={closeNavDialog} />
      <FailedSaveDialog open={open} setOpen={setOpen} /> */}
    </div>
  );
};

export default ReviewAndSelect;
