import React, { useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactIsCapsLockActive from "@matsun/reactiscapslockactive";

import Footer from "../../components/Footer";
import { postRequest } from "../../API/ApiHandler";

import "./Login.css";
import {
  getUserType,
  getCurrentUser,
  type
} from "../../components/AuthenticationService";
import TranslateButton from "../../components/UI/TranslateButton";
import "./Login.css";

import ReCAPTCHA from "react-google-recaptcha";
import XRegExp from "xregexp";
import ChooseLang from "../../components/Home/ChooseLang";

const Login = ({ history }) => {
  const { register, handleSubmit, errors } = useForm();
  const [invalidUser, setInvalidUser] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  //Get value of language from local storage
  let lang = localStorage.getItem("language");
  let popup = localStorage.getItem("popup");

  if (popup == null) {
    localStorage.setItem("popup", true);
    window.location.reload();
  }
  const captchaRef = useRef(null);
  const onSubmit = async (values, helpers) => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setDisableSubmit(true);
    const signInInput = {
      username: helpers.userID,
      password: helpers.password,
      token: token
    };
    let success = await postRequest("/auth/tokens", signInInput);

    if (success) {
      await getCurrentUser();
      await getUserType();
      setInvalidUser(false);
      type === "user"
        ? history.push(`/home`)
        : history.push(`/adminPanel/users`);
      localStorage.setItem("popup", true);
    } else {
      setInvalidUser(true);
    }
  };

  const initialValues = {
    userID: "",
    password: ""
  };

  var regexUsername = XRegExp("^[\\p{L}\\p{N}]+$");
  // The below Regex allows passwords such as: strictly formatted 'AF2HJD74' or (exclusive or) strictly formatted 'James12@@'
  var regexPass = XRegExp(
    "^(?=.*[\\p{Lu}])(?=.*[\\pN])[\\p{Lu}\\p{N}]+$|(^(?=.*[\\p{Lu}])(?=.*[\\p{N}])(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.*[\\p{Ll}])[\\p{Lu}\\p{N}\\p{Ll}!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]+$)"
  );
  const validationSchema = Yup.object({
    userID: Yup.string()
      .matches(
        regexUsername,
        lang === "English"
          ? "Invalid User ID format"
          : "Format de l'ID utilisateur invalide"
      )
      .required(
        lang === "English"
          ? "User ID is required"
          : "L'ID utilisateur est requis"
      ),

    password: Yup.string()
      .min(
        8,
        lang === "English"
          ? "Password must be at least 8 characters long"
          : "Le mot de passe doit comporter au moins 8 caractères"
      )
      .matches(
        regexPass,
        lang === "English"
          ? "Invalid password format"
          : "Format de mot de passe invalide"
      )
      .required(
        lang === "English" ? "Password is required" : "Mot de passe requis"
      )
  });

  return (
    <div className="login-page">
      <div className="title-section">
        <h1>JIA Option Map</h1>
        <div>
          <div className="login-translation">
            {" "}
            <TranslateButton />{" "}
          </div>
        </div>
      </div>

      {popup === "true" ? <ChooseLang /> : ""}

      <div className="form-section">
        <h2>{lang === "English" ? "Login" : "Connexion"}</h2>
        {invalidUser && (
          <p className="error-message">
            {lang === "English"
              ? "Invalid user ID or password"
              : "ID utilisateur ou mot de passe invalide"}
          </p>
        )}
        {history.location.state?.timeout && (
          <p className="alert alert-warning">
            {" "}
            {lang === "English"
              ? "Your session has timed out and your data has been saved."
              : "Votre session a expiré et vos données ont été enregistrées."}{" "}
          </p>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit(onSubmit)}
          enableReinitialize={true}
        >
          <Form className="form-content">
            <div className="input-container">
              <label>
                <PersonIcon style={{ fill: "#10434F" }} />
                {lang === "English" ? "User ID" : "Nom d’utilisateur"}
              </label>
              <Field name="userID" type="text" />
              <ErrorMessage
                name="userID"
                component="p"
                className="error-message"
              />
            </div>
            <div className="input-container">
              <label>
                <LockIcon style={{ fill: "#10434F" }} />
                {lang === "English" ? "Password " : "Mot de passe "}
                <ReactIsCapsLockActive>
                  {(active) => (
                    <span>
                      {active
                        ? lang === "English"
                          ? "[Caps lock: ON]"
                          : "[Majuscules: Activées]"
                        : ""}
                    </span>
                  )}
                </ReactIsCapsLockActive>
              </label>

              <Field name="password" type="password" />
              <ErrorMessage
                name="password"
                component="p"
                className="error-message"
              />
              <Link to="/reset" className="forgotPass">
                {lang === "English"
                  ? "Forgot your password ?"
                  : "Vous avez oublié votre mot de passe ?"}
              </Link>
            </div>
            <br />
            <ReCAPTCHA
              sitekey="6LcTLwklAAAAAJAXmh3Dgu_-SRm0i4aJOsdIKnP4"
              onChange={() => setDisableSubmit(false)}
              ref={captchaRef}
              hl={lang === "English" ? "en" : "fr"}
            />
            <button
              disabled={disableSubmit}
              type="submit"
              className="submit-btn"
              name="submitBtn"
            >
              {lang === "English" ? "Log in" : "Connexion"}
            </button>
          </Form>
        </Formik>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Login);
