/**
 * @file
 * Component for displaying the treatment details when it is viewed
 * @author <akel.ms93@gmail.com>
 */

import printJS from "print-js";
import React, { useEffect, useState } from "react";
import { Button, Collapse } from "reactstrap";
import {
  baseUrl,
  deleteRequest,
  getRequest,
  postRequest
} from "../../API/ApiHandler";
import Footer from "../Footer";
import Header from "../Home/Header";
import FavouriteIcon from "../UI/Icons/FavouriteIcon";
import TrafficLight from "../UI/TrafficLight";
import Study from "./Study";

import placeholder from "../../img/default_placeholder.png";
import IdleComponent from "../IdleComponent";
import "./TreatmentDetails.css";
import Subheader from "../UI/Subheader/Subheader";

const TreatmentDetails = (props) => {
  const [treatment, setTreatment] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [studies, setStudies] = useState([]);
  const [webLinks, setWebLinks] = useState([]);
  const [videoLinks, setVideoLinks] = useState([]);
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);

  const [navTo, setNavTo] = useState("");

  const toggleFavourite = (ev) => {
    const path = "/userFavourites";
    const input = { treatment_id: treatment.id };
    if (ev.target.getAttribute("data-active") === "false") {
      postRequest(path, input);
      setIsSelected(true);
    } else {
      deleteRequest(path, input);
      setIsSelected(false);
    }
  };

  const getWebLinks = async () => {
    let data = await getRequest(`/learn/${treatment.id}`);
    if (data.length) setWebLinks(data);
  };

  const getVideoLinks = async () => {
    let data = await getRequest(`/videos/${treatment.id}`);
    if (data.length) setVideoLinks(data);
  };

  const getStudies = async () => {
    let data = await getRequest(`/studies/${treatment.id}`);
    if (data.length) setStudies(data);
  };

  function getNumOfLinksByLang(lang) {
    let numOfLinks = 0;
    webLinks.map((web) => {
      if (web.language === lang && web.is_app === false) {
        numOfLinks += 1;
      }
    });
    return numOfLinks;
  }

  function getNumOfLinksByNationAndLang(nation, lang) {
    let numOfLinks = 0;
    webLinks.map((web) => {
      if (
        web.nationality === nation &&
        web.language === lang &&
        web.is_app === false
      ) {
        numOfLinks += 1;
      }
    });
    return numOfLinks;
  }

  function getNumOfAppByLang(lang) {
    let numOfApps = 0;
    webLinks.map((web) => {
      if (web.is_app === true && web.language === lang) {
        numOfApps += 1;
      }
    });
    return numOfApps;
  }

  function getNumOfVids(lang) {
    let numOfVids = 0;
    videoLinks.map((video) => {
      if (video.language === lang) {
        numOfVids += 1;
      }
    });
    return numOfVids;
  }

  const handleStepperNav = (to) => {
    props.data.history.push(to);
  };

  useEffect(() => {
    setTreatment(props.data.location.state[0]);
    setIsSelected(props.data.location.state[1]);
  }, []);

  useEffect(() => {
    if (treatment) {
      getWebLinks();
      getVideoLinks();
      getStudies();
    }
  }, [treatment]);

  // const downloadPDF = async () => {
  //   // const res = await getRequest(`/pdf/Night`)
  //   const res = await fetch(`http://localhost:3030/pdf/${treatment.name}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/pdf",
  //       "Content-Type": "application/json",
  //       mode: "cors",
  //       credentials: "include",
  //     },
  //   });
  //   const blob = await res.blob();

  //   // var fileURL = URL.createObjectURL(blob);
  //   //     window.open(fileURL);
  //   download(blob, `${treatment.name}.pdf`);
  //   // console.log(treatment.name)
  // };

  const downloadPDF = async () => {
    printJS({
      printable: "printForm",
      type: "html",
      targetStyles: ["*"],
      maxWidth: "1200",
      documentTitle: lang === "English" ? treatment.name : treatment.fr_name
    });
  };
  //console.log(treatment.name)

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  const makePage = () => {
    return (
      <React.Fragment>
        <div className="wrapper">
          <IdleComponent history={props.data.history} />
          <div className="page-header">
            <Header
              current={props.data}
              handleNav={handleStepperNav}
              setNavTo={setNavTo}
            />
          </div>
          <div className="page-subheader">
            <Subheader
              title={
                lang === "English"
                  ? "Treatment details"
                  : "Détails du traitement"
              }
            />
          </div>

          {/* <div className="body-container treatment_details"> */}
          <div className="body-container ">
            <div className="back-button-container">
              <Button
                className="next-btn"
                onClick={() => {
                  props.data.history.goBack();
                }}
              >
                {lang === "English" ? "Go Back" : "Retourner"}
              </Button>
            </div>
            <div className="box-container" id="printForm">
              {/* Image and description */}
              <div id="image_and_description">
                <img
                  alt={treatment.name}
                  src={
                    treatment.image
                      ? `${baseUrl}/${treatment.image}`
                      : placeholder
                  }
                />
                <div className="description">
                  <div className="download-btn-top">
                    <Button className="next-btn" onClick={downloadPDF}>
                      {lang === "English" ? "Download" : "Télécharger"}
                    </Button>
                  </div>
                  <div className="description-title">
                    <h2>
                      {lang === "English" ? treatment.name : treatment.fr_name}
                    </h2>
                    <span>
                      <FavouriteIcon
                        toggleFav={toggleFavourite}
                        isFav={isSelected}
                      />
                    </span>
                  </div>
                  <p>
                    {lang === "English"
                      ? treatment.description
                      : treatment.fr_description}
                  </p>
                </div>
              </div>

              <div id="traffic_and_description">
                <div className="traffic-light-container">
                  <TrafficLight level={treatment.traffic_level} />
                </div>
                <div className="traffic-description">
                  <p>
                    {lang === "English"
                      ? treatment.traffic_description
                      : treatment.fr_traffic_description}
                  </p>
                </div>
              </div>

              <div className="treatment_details_overview">
                <h3 id="experts_suggest">
                  {lang === "English"
                    ? "What do experts suggest?"
                    : "Que suggèrent les experts?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.experts_suggest
                    : treatment.fr_experts_suggest}
                </p>
              </div>

              {/* Studies section */}
              <div className="research_heading">
                <h3>
                  {lang === "English"
                    ? "What does the research say?"
                    : "Que dit la recherche?"}
                </h3>
              </div>

              {/* Orange tabs for each symptoms */}
              {/* Pain */}
              <div className="research_heading">
                {studies && (
                  <div className="treatment_details_study_container">
                    <div className="box-container-numbered">
                      <div
                        className="obvious"
                        onClick={() => setCollapse1(!collapse1)}
                      >
                        <h4 className="study-numbered-subheading">
                          {lang === "English" ? "Pain" : "Douleur"}
                          <span className="collapsed-icon text-big">
                            {collapse1
                              ? lang === "English"
                                ? "(Collapse) ▲"
                                : "(Réduire) ▲"
                              : lang === "English"
                              ? "(Expand) ▼"
                              : "(Développer) ▼"}
                          </span>
                        </h4>
                      </div>
                      <Collapse
                        isOpen={collapse1}
                        style={{ marginBottom: "2rem", padding: "1rem" }}
                      >
                        {studies && (
                          <div className="treatment_details_study_container col-11">
                            {studies.map((study, i) => (
                              <Study
                                key={study.id}
                                study={study}
                                studyNo={i + 1}
                              />
                            ))}
                          </div>
                        )}

                        {/* <div className="treatment-details-texts">
                            <h3>
                              {lang === "English"
                                ? "How can I use this treatment?"
                                : "Comment puis-je utiliser ce traitement?"}
                            </h3>
                            <p>
                              {lang === "English"
                                ? treatment.how_use
                                : treatment.fr_how_use}
                            </p>

                            <h3>
                              {lang === "English"
                                ? "What else should I consider?"
                                : "Que dois-je envisager d'autre?"}
                            </h3>
                            <p>
                              {lang === "English"
                                ? treatment.consider
                                : treatment.fr_consider}
                            </p>
                          </div> */}
                      </Collapse>
                    </div>
                  </div>
                )}
                {/* Fatigue */}
                {studies && (
                  <div className="treatment_details_study_container">
                    <div className="box-container-numbered">
                      <div
                        className="obvious"
                        onClick={() => setCollapse2(!collapse2)}
                      >
                        <h4 className="study-numbered-subheading">
                          {lang === "English" ? "Fatigue" : "Fatigue"}
                          <span className="collapsed-icon text-big">
                            {collapse2
                              ? lang === "English"
                                ? "(Collapse) ▲"
                                : "(Réduire) ▲"
                              : lang === "English"
                              ? "(Expand) ▼"
                              : "(Développer) ▼"}
                          </span>
                        </h4>
                      </div>
                      <Collapse
                        isOpen={collapse2}
                        style={{ marginBottom: "2rem" }}
                      >
                        <div className="treatment_details_study_container col-11">
                          <h4 className="text-medium">
                            {lang === "English"
                              ? "  Does it work?"
                              : "  Est-ce que ça marche?"}
                          </h4>
                          <p className="text-small-unbold">
                            {lang === "English"
                              ? "  Researchers need to explore whether Pilates reduce fatigue in JIA. There isn’t enough research yet."
                              : "  Les chercheurs doivent déterminer si les interventions d’ergothérapie réduisent la fatigue chez les personnes atteintes d’AJI. Il n’y a pas encore suffisamment d’études à ce sujet."}
                          </p>

                          <h4 className="text-medium">
                            {lang === "English"
                              ? "  Is it safe?"
                              : "Est-ce sécuritaire?"}
                          </h4>
                          <p className="text-small-unbold">
                            {lang === "English"
                              ? "  A study that assessed pain showed no side effects."
                              : "Une étude qui a évalué la douleur n’a démontré aucun effet secondaire."}
                          </p>
                        </div>
                        {/* <StudyDetails
                          study={study}
                          onClick={() => setCollapse(!collapse)}
                        /> */}
                      </Collapse>
                    </div>
                  </div>
                )}
                {/* Stiffness */}
                {studies && (
                  <div className="treatment_details_study_container">
                    <div className="box-container-numbered">
                      <div
                        className="obvious"
                        onClick={() => setCollapse3(!collapse3)}
                      >
                        <h4 className="study-numbered-subheading">
                          {lang === "English" ? "Stiffness" : "Raideurs"}
                          <span className="collapsed-icon text-big">
                            {collapse3
                              ? lang === "English"
                                ? "(Collapse) ▲"
                                : "(Réduire) ▲"
                              : lang === "English"
                              ? "(Expand) ▼"
                              : "(Développer) ▼"}
                          </span>
                        </h4>
                      </div>
                      <Collapse
                        isOpen={collapse3}
                        style={{ marginBottom: "2rem" }}
                      >
                        <div className="treatment_details_study_container col-11">
                          <div className="treatment_details_overview">
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Does it work?"
                                : "  Est-ce que ça marche?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  Researchers need to explore whether Pilates reduce stiffness in JIA. There isn’t enough research yet."
                                : "  Les chercheurs doivent déterminer si les interventions d’ergothérapie réduisent les raideurs articulaires chez les personnes atteintes d’AJI. Il n’y a pas encore suffisamment d’études à ce sujet."}
                            </p>
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Is it safe?"
                                : "  Est-ce sécuritaire?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  A study that assessed pain showed no side effects."
                                : "  Une étude qui a évalué la douleur n’a démontré aucun effet secondaire."}
                            </p>
                          </div>
                        </div>
                        {/* <StudyDetails
                          study={study}
                          onClick={() => setCollapse(!collapse)}
                        /> */}
                      </Collapse>
                    </div>
                  </div>
                )}
                {/* Stress */}
                {studies && (
                  <div className="treatment_details_study_container">
                    <div className="box-container-numbered">
                      <div
                        className="obvious"
                        onClick={() => setCollapse4(!collapse4)}
                      >
                        <h4 className="study-numbered-subheading">
                          {lang === "English" ? "Stress" : "Stress"}
                          <span className="collapsed-icon text-big">
                            {collapse4
                              ? lang === "English"
                                ? "(Collapse) ▲"
                                : "(Réduire) ▲"
                              : lang === "English"
                              ? "(Expand) ▼"
                              : "(Développer) ▼"}
                          </span>
                        </h4>
                      </div>
                      <Collapse
                        isOpen={collapse4}
                        style={{ marginBottom: "2rem" }}
                      >
                        <div className="treatment_details_study_container col-11">
                          <div className="treatment_details_overview">
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Does it work?"
                                : "  Est-ce que ça marche?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  Researchers need to explore whether Pilates reduce stress in JIA. There isn’t enough research yet."
                                : "  Les chercheurs doivent déterminer si les interventions d’ergothérapie réduisent le stress chez les personnes atteintes d’AJI. Il n’y a pas encore suffisamment d’études à ce sujet."}
                            </p>
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Is it safe?"
                                : "  Est-ce sécuritaire?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  A study that assessed pain showed no side effects."
                                : "  Une étude qui a évalué la douleur n’a démontré aucun effet secondaire."}
                            </p>
                          </div>
                        </div>
                        {/* <StudyDetails
                          study={study}
                          onClick={() => setCollapse(!collapse)}
                        /> */}
                      </Collapse>
                    </div>
                  </div>
                )}
                {/* Anxiety */}
                {studies && (
                  <div className="treatment_details_study_container">
                    <div className="box-container-numbered">
                      <div
                        className="obvious"
                        onClick={() => setCollapse5(!collapse5)}
                      >
                        <h4 className="study-numbered-subheading">
                          {lang === "English" ? "Anxiety" : "Anxiété"}
                          <span className="collapsed-icon text-big">
                            {collapse5
                              ? lang === "English"
                                ? "(Collapse) ▲"
                                : "(Réduire) ▲"
                              : lang === "English"
                              ? "(Expand) ▼"
                              : "(Développer) ▼"}
                          </span>
                        </h4>
                      </div>
                      <Collapse
                        isOpen={collapse5}
                        style={{ marginBottom: "2rem" }}
                      >
                        <div className="treatment_details_study_container col-11">
                          <div className="treatment_details_overview">
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Does it work?"
                                : "  Est-ce que ça marche?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  Researchers need to explore whether Pilates reduce anxiety in JIA. There isn’t enough research yet."
                                : "  Les chercheurs doivent déterminer si les interventions d’ergothérapie réduisent l’anxiété chez les personnes atteintes d’AJI. Il n’y a pas encore suffisamment d’études à ce sujet."}
                            </p>
                            <h4 className="text-medium">
                              {lang === "English"
                                ? "  Is it safe?"
                                : "  Est-ce sécuritaire?"}
                            </h4>
                            <p className="text-small-unbold">
                              {lang === "English"
                                ? "  A study that assessed pain showed no side effects."
                                : "  Une étude qui a évalué la douleur n’a démontré aucun effet secondaire."}
                            </p>
                          </div>
                        </div>
                        {/* <StudyDetails
                          study={study}
                          onClick={() => setCollapse(!collapse)}
                        /> */}
                      </Collapse>
                    </div>
                  </div>
                )}
              </div>

              {/* treatment details section */}
              <div className="treatment-details-texts">
                <h3>
                  {lang === "English"
                    ? "How can I use this treatment?"
                    : "Comment puis-je utiliser ce traitement?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.how_use
                    : treatment.fr_how_use}
                </p>
                <h3>
                  {lang === "English"
                    ? "How soon will I feel better?"
                    : "Quand vais-je me sentir mieux?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.how_soon
                    : treatment.fr_how_soon}
                </p>
              </div>

              <div className="treatment_details_overview">
                <h3>
                  {lang === "English"
                    ? "How much does this treatment cost?"
                    : "Combien coûte ce traitement?"}
                </h3>
                <p>{lang === "English" ? treatment.cost : treatment.fr_cost}</p>
              </div>

              <div className="treatment_details_overview">
                <h3>
                  {lang === "English"
                    ? "Where can I get this treatment?"
                    : "Où puis-je obtenir ce traitement?"}
                </h3>
                <p>
                  {lang === "English" ? treatment.where : treatment.fr_where}
                </p>
              </div>

              {/* <div className="treatment_details_overview">
                <h3>
                  {lang === "English"
                    ? "How can " + treatment.name + " help with symptoms?"
                    : "Comment le " +
                      treatment.fr_name +
                      " peut-il aider avec les symptômes?"}
                </h3>
              </div> */}

              {/* Studies section */}
              {/* <div className="research_heading">
                <h3>
                  {lang === "English"
                    ? "What does the research say?"
                    : "Que dit la recherche?"}
                </h3>
              </div> */}
              {/* {studies && (
                <div className="treatment_details_study_container">
                  {studies.map((study, i) => (
                    <Study key={study.id} study={study} studyNo={i + 1} />
                  ))}
                </div>
              )} */}
              {/* treatment details section */}
              <div className="treatment-details-texts">
                {/* <h3>
                  {lang === "English"
                    ? "How can I use this treatment?"
                    : "Comment puis-je utiliser ce traitement?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.how_use
                    : treatment.fr_how_use}
                </p>
                
                <h3>
                  {lang === "English"
                    ? "What else should I consider?"
                    : "Que dois-je envisager d'autre?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.consider
                    : treatment.fr_consider}
                </p>

                <h3>
                  {lang === "English"
                    ? "Learn more:"
                    : "Apprendre encore plus:"}
                </h3> */}

                {getNumOfLinksByLang("English") > 0 && lang === "English" ? (
                  <h4>Web pages:</h4>
                ) : (
                  ""
                )}
                {getNumOfLinksByLang("French") +
                  getNumOfLinksByLang("English") >
                  0 && lang === "French" ? (
                  <h4>Pages web:</h4>
                ) : (
                  ""
                )}

                <div className="treatment_details_links">
                  {getNumOfLinksByNationAndLang("Canadian", "English") > 0 &&
                  lang === "English" ? (
                    <h5>Canadian web pages:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("Canadian", "French") +
                    getNumOfLinksByNationAndLang("Canadian", "English") >
                    0 && lang === "French" ? (
                    <h5>Pages web canadiennes:</h5>
                  ) : (
                    ""
                  )}
                  {webLinks.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "Canadian" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "Canadian" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}

                  {getNumOfLinksByNationAndLang("American", "English") > 0 &&
                  lang === "English" ? (
                    <h5>American web pages:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("American", "French") +
                    getNumOfLinksByNationAndLang("American", "English") >
                    0 && lang === "French" ? (
                    <h5>Pages web américaines:</h5>
                  ) : (
                    ""
                  )}
                  {webLinks.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "American" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "American" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}

                  {getNumOfLinksByNationAndLang("Other", "English") > 0 &&
                  lang === "English" ? (
                    <h5>Web pages from other countries:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("Other", "French") +
                    getNumOfLinksByNationAndLang("Other", "English") >
                    0 && lang === "French" ? (
                    <h5>Pages web d'autres pays:</h5>
                  ) : (
                    ""
                  )}
                  {webLinks.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "Other" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "Other" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>

                {getNumOfVids("English") > 0 && lang === "English" ? (
                  <h4>Videos:</h4>
                ) : (
                  ""
                )}
                {getNumOfVids("French") + getNumOfVids("English") > 0 &&
                lang === "French" ? (
                  <h4>Vidéos:</h4>
                ) : (
                  ""
                )}
                <div className="treatment_details_links">
                  {videoLinks.map((video, i) =>
                    lang === "English" && video.language === "English" ? (
                      <p key={i}>
                        <a
                          href={`${video.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {video.name ? `${video.name}` : `${video.link}`}
                        </a>
                      </p>
                    ) : lang === "French" ? (
                      <p key={i}>
                        <a
                          href={`${video.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {video.name ? `${video.name}` : `${video.link}`}
                        </a>
                      </p>
                    ) : (
                      ""
                    )
                  )}
                </div>

                {getNumOfAppByLang("English") > 0 && lang === "English" ? (
                  <h4>Apps:</h4>
                ) : (
                  ""
                )}
                {getNumOfAppByLang("French") + getNumOfAppByLang("English") >
                  0 && lang === "French" ? (
                  <h4>Applis:</h4>
                ) : (
                  ""
                )}
                {webLinks.map((web, i) => (
                  <div key={web.id}>
                    {lang === "English" &&
                    web.language === "English" &&
                    web.is_app === true ? (
                      <p key={i}>
                        <a
                          href={`${web.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {web.name ? `${web.name}` : `${web.link}`}
                        </a>
                      </p>
                    ) : lang === "French" && web.is_app === true ? (
                      <p key={i}>
                        <a
                          href={`${web.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {web.name ? `${web.name}` : `${web.link}`}
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>

              <div className="save-as-favourite">
                <h5>
                  {lang === "English"
                    ? "Save to favourites"
                    : "Enregistrer dans les favoris"}
                </h5>
                <span>
                  <FavouriteIcon
                    toggleFav={toggleFavourite}
                    isFav={isSelected}
                  />
                </span>
                <div className="download-btn-btm">
                  <Button className="next-btn" onClick={downloadPDF}>
                    {lang === "English" ? "Download" : "Télécharger"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="back-button-container">
              <Button
                className="next-btn"
                onClick={() => props.data.history.goBack()}
              >
                {lang === "English" ? "Go Back" : "Retourner"}
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  };

  return <div id="treatment_details_div">{treatment && makePage()}</div>;
};

export default TreatmentDetails;
