import printJS from "print-js";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  baseUrl,
  deleteRequest,
  getRequest,
  postRequest
} from "../../API/ApiHandler";
import placeholder from "../../img/default_placeholder.png";
import Header from "../Home/Header";
import IdleComponent from "../IdleComponent";
import FavouriteIcon from "../UI/Icons/FavouriteIcon";
import Subheader from "../UI/Subheader/Subheader";
import TrafficLight from "../UI/TrafficLight";
import Subcategory from "./Subcategory";
import "./TipDetails.css";

const TipDetails = (props) => {
  const [tip, setTip] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [navTo, setNavTo] = useState("");
  const [subcategories, setSubcategories] = useState([]);

  const getSubcategories = async () => {
    let data = await getRequest(`/subcategories/${tip.id}`);
    if (data) setSubcategories(data);
  };
  const toggleFavourite = (ev) => {
    const path = "/userFavouriteTips";
    const input = { tip_id: tip.id };
    if (ev.target.getAttribute("data-active") === "false") {
      postRequest(path, input);
      setIsSelected(true);
    } else {
      deleteRequest(path, input);
      setIsSelected(false);
    }
  };

  const handleStepperNav = (to) => {
    props.data.history.push(to);
  };

  // console.log(tip)

  useEffect(() => {
    setTip(props.data.location.state[0]);
    setIsSelected(props.data.location.state[1]);
  }, []);

  useEffect(() => {
    if (tip) getSubcategories();
  }, [tip]);

  const downloadPDF = async () => {
    printJS({
      printable: "printForm",
      type: "html",
      targetStyles: ["*"],
      maxWidth: "1200",
      documentTitle: lang === "English" ? tip.name : tip.fr_name
    });
  };

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  const makePage = () => {
    return (
      <React.Fragment>
        <div className="wrapper">
          <IdleComponent history={props.data.history} />
          <div className="page-header">
            <Header
              current={props.data}
              handleNav={handleStepperNav}
              setNavTo={setNavTo}
            />
          </div>

          <div className="page-subheader">
            <Subheader
              title={lang === "English" ? "Tip details" : "Détails des trucs"}
            />
          </div>

          {/* <div className="body-container treatment_details"> */}
          <div className="body-container">
            <div className="back-button-container">
              <Button
                className="next-btn"
                onClick={() => {
                  props.data.history.goBack();
                }}
              >
                {lang === "English" ? "Go Back" : "Retourner"}
              </Button>
            </div>
            <div className="box-container" id="printForm">
              {/* Image and description */}
              <div id="image_and_description">
                <img
                  alt={tip.name + "tips"}
                  src={tip.image ? `${baseUrl}/${tip.image}` : placeholder}
                />
                <div className="description">
                  <div className="download-btn-top">
                    <Button className="next-btn" onClick={downloadPDF}>
                      {lang === "English" ? "Download" : "Télécharger"}
                    </Button>
                  </div>
                  <div className="description-title">
                    <h2>{lang === "English" ? tip.name : tip.fr_name}</h2>
                    <span>
                      <FavouriteIcon
                        toggleFav={toggleFavourite}
                        isFav={isSelected}
                      />
                    </span>
                  </div>
                  <p>
                    {lang === "English" ? tip.description : tip.fr_description}
                  </p>
                </div>
              </div>
              <div id="traffic_and_description">
                <div className="traffic-light-container">
                  <TrafficLight level={tip.traffic_level} />
                </div>
                <div className="traffic-description">
                  <p>
                    {lang === "English"
                      ? tip.traffic_description
                      : tip.fr_traffic_description}
                  </p>
                </div>
              </div>

              <div className="treatment_details_overview">
                <h3 id="experts_suggest">
                  {lang === "English"
                    ? "Here are suggested tips for the activities:"
                    : "Voici des trucs suggérés pour les activités:"}
                </h3>
              </div>

              {subcategories && (
                <div className="treatment_details_study_container">
                  {subcategories.map((subcategory, i) => (
                    <Subcategory
                      key={subcategory.id}
                      subcategory={subcategory}
                      subNo={i + 1}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return <div id="tip_details_div">{tip && makePage()}</div>;
};

export default TipDetails;
