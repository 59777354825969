import React, { useEffect, useState } from "react";
import { getRequest } from "../../API/ApiHandler";
import Spinner from "../UI/Spinner";
import PrintTreatments from "./PrintTreatments";
import "./Summary.css";

const StepThree = ({ hidden }) => {
  const [sc, setSc] = useState([]);
  const [hcp, setHcp] = useState([]);
  const [tips, setTips] = useState([]);
  const [favs, setFavs] = useState([]);
  const [favTips, setFavTips] = useState([]);
  const [plans, setPlan] = useState([]);
  const [tipPlan, setTipPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserSc = async () => {
    let data = await getRequest(`/userScs/user`);
    if (data) setSc(data);
    setIsLoading(false);
  };

  const getUserHcp = async () => {
    let data = await getRequest(`/userHcps/user`);
    if (data) setHcp(data);
    setIsLoading(false);
  };
  const getUserTips = async () => {
    let tips = await getRequest(`/userTips/user`);
    if (tips) {
      let { data } = tips;
      setTips(data);
    }
  };
  const getUserFavs = async () => {
    let data = await getRequest(`/userFavourites/user`);
    if (data) setFavs(data);
    setIsLoading(false);
  };

  const getUserFavsTips = async () => {
    let data = await getRequest(`/userFavouriteTips/user`);
    if (data) setFavTips(data);
    setIsLoading(false);
  };

  const getUserPlans = async () => {
    let data = await getRequest(`/userPlans/user`);
    if (data) setPlan(data);
    setIsLoading(false);
  };

  const getUserTipPlans = async () => {
    let data = await getRequest(`/userTipPlans/user`);
    if (data) setTipPlan(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getUserSc();
    getUserHcp();
    getUserTips();
    getUserFavs();
    getUserFavsTips();
    getUserPlans();
    getUserTipPlans();
  }, []);

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  const topThreeSC =
    sc &&
    sc.map((sc) => (
      <li key={sc.id}>{lang === "English" ? sc.name : sc.fr_name}</li>
    ));

  const topThreeHCP =
    hcp &&
    hcp.map((hcp) => (
      <li key={hcp.id}>{lang === "English" ? hcp.name : hcp.fr_name}</li>
    ));

  const topThreeTips =
    tips &&
    tips.map((tips) => (
      <li key={tips.id}>{lang === "English" ? tips.name : tips.fr_name}</li>
    ));
  
  //Checks if the favorite treatments and favorite tips have any between them, if not display the message
  const favoritesList = (favs.length || favTips.length) ? (
    favs.map((fav) => (
      <li key={fav.id}>{lang === "English" ? fav.name : fav.fr_name}</li>
    ))
    ): (
      <p className="text-left">
        {lang === "English"
          ? "Your favorite list is empty"
          : "Votre liste de favori est vide"}
      </p>
    );
  
  if(favTips.length){//The tips are added afterwards only if there are any because they really didn't like being added at the same time
    favoritesList.push(favTips.map((fav) => (
      <li key={fav.id}>{lang === "English" ? fav.name : fav.fr_name}</li>
    )))}



    
  const scList = plans.length ? (
    plans.map((plan) => (
      <li key={plan.id}>
        {plan.supervision === "SC"
          ? lang === "English"
            ? plan.name
            : plan.fr_name
          : ""}
      </li>
    ))
  ) : (
    <p className="text-left">
      {lang === "English"
        ? "Your plan list is empty"
        : "Votre liste de plans est vide"}
    </p>
  );

  const hcpList = plans.length ? (
    plans.map((plan) => (
      <li key={plan.id}>
        {plan.supervision === "HCP"
          ? lang === "English"
            ? plan.name
            : plan.fr_name
          : ""}
      </li>
    ))
  ) : (
    <p className="text-left">
      {lang === "English"
        ? "Your plan list is empty"
        : "Votre liste de plans est vide"}
    </p>
  );

  const tipList = tipPlan.length ? (
    tipPlan.map((plan) => (
      <li key={plan.id}>{lang === "English" ? plan.name : plan.fr_name}</li>
    ))
  ) : (
    <p className="text-left">
      {lang === "English"
        ? "Your plan list is empty"
        : "Votre liste de plans est vide"}
    </p>
  );

  return (
    <div className="box-container-numbered">
      <p className="title_summary numbered-subheading">
        {lang === "English"
          ? "Step 3: The treatments and tips you wish to use "
          : "Étape 3: Les traitements et trucs que vous souhaitez utiliser"}
      </p>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="padding-class">
          <h5 className="subheader-summary">
            {lang === "English"
              ? "1. Suggested treatments"
              : "1. Traitements suggérés"}
          </h5>

          <h6 className="subtitle">
            {lang === "English"
              ? "Treatments you could try now:"
              : "Traitements que vous pouvez essayer maintenant:"}
            {!hidden && <PrintTreatments type={"sc"} passSC={sc} />}
          </h6>
          {topThreeSC}
          <br />
          <h6 className="subtitle">
            {lang === "English"
              ? "Treatments you could talk about with your health care team before trying:"
              : "Traitements dont vous pourriez parler avec votre équipe soignante avant de les essayer:"}
            {!hidden && <PrintTreatments type={"hcp"} passHCP={hcp} />}
          </h6>
          {topThreeHCP}
          <br />
          <h6 className="subtitle">
            {lang === "English"
              ? "Activity tips you could try now:"
              : "Trucs d'activité que vous pouvez essayer maintenant:"}
            {!hidden && <PrintTreatments type={"tips"} passTips={tips} />}
          </h6>
          {topThreeTips}

          <h5 className="subheader-summary">
            {lang === "English"
              ? "2. Treatments and tips in your Favourites list:"
              : "2. Traitements et trucs dans votre liste de favoris:"}
            {!hidden && <PrintTreatments type={"favs"} passFavs={favs} />}
          </h5>
          
          {favoritesList.length
          ? favoritesList
          :<p className='text-left'>
              {lang === "English"
                ? 'Your favourite list is empty'
                : 'Votre liste de favoris est vide'}
            </p>
          }

          <h5 className="subheader-summary">
            {lang === "English"
              ? "3. Your treatment plan:"
              : "3. Votre plan de traitement:"}
            {!hidden && <PrintTreatments type={"plan"} passPlan={plans} />}
          </h5>

          <h6 className="subtitle">
            {lang === "English"
              ? "Treatments you wish to try now:"
              : "Traitements que vous pouvez essayer maintenant:"}
          </h6>
          {scList}

          <h6 className="subtitle">
            <br />
            {lang === "English"
              ? "Treatments you wish to talk about with your health care team before trying:"
              : "Traitements dont vous pourriez parler avec votre équipe soignante avant de les essayer:"}
          </h6>
          {hcpList}
          <h6 className="subtitle">
            <br />
            {lang === "English"
              ? "Tips you wish to try now:"
              : "Trucs que vous souhaitez essayer maintenant:"}
          </h6>
          {tipList}
        </div>
      )}
    </div>
  );
};

export default StepThree;
