import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getRequest, patchRequest, postRequest, deleteRequest } from '../../API/ApiHandler';
import './Reminders.css';

const Reminders = () => {
  const lang = localStorage.getItem("language");
  const [reminderData, setReminderData] = useState(null);
  const [confirmReminders, setConfirmReminders] = useState(false);
  const [reminderType, setReminderType] = useState('tel');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [interval, setIntervalType] = useState('daily');
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState('default')

  const getUser = async () => {
    let response = await getRequest('/auth/users/current/me');
    let user = '';
    if (response) {
      user = response.data.id;
    }
    setUser(user);
  }

  useEffect(() => {
    getUser();
  }, []);

  const getReminderData = async () => {
    let response = await getRequest(`/reminders/${user}`);
    if (!response || !Object.keys(response).includes('user_id')) {
      // No reminder data
      setReminderData(false);
      return;
    } else {
      setEmail(response.email_id);
      setPhone(response.sms);
      setIntervalType(response.interval);
      setReminderType(response.reminder_type);
      setReminderData(true);
    }
  }

  useEffect(() => {
    if (user) {
      getReminderData();
    }
  }, [user]);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  const disableReminder = async () => {
    console.log('disableReminder');
    if (reminderData) {
      // delete request
      const response = await deleteRequest(`/reminders/${user}`, {
        user_id: user,
      });
      alert(lang === "English" ? "Reminder has been deleted." : "Le rappel a été supprimé.")
      setStatus("submitted")
      console.log(response);
    } else {
      // alert
      alert(
        lang === "English"
          ? "You have not selected any reminders."
          : "Vous n'avez sélectionné aucun rappel."
      );
      setStatus("default")
    }
  }

  const submitReminderData = async () => {
    if (reminderType === "tel") {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (!re.test(phone)) {
        alert(lang === "English" ? "Not a valid phone number" : "Pas un numéro de téléphoner valide")
      } else {
        if (window.confirm(lang === "English" ?
          "We need to provide your phone number to Twilio to use this service.\n\nIf you agree, please click 'OK' to continue." :
          "Nous devons fournir votre numéro de téléphone à Twilio pour utiliser ce service.\n\nSi vous êtes d'accord, veuillez cliquer sur 'OK' pour continuer.")) {
          if (reminderData) {
            // patch request
            const response = await patchRequest(`/reminders/${user}`, {
              user_id: user,
              email_id: email,
              interval,
              lang,
              reminder_type: reminderType,
              sms: formatPhoneNumber(phone),
            });
            alert(lang === "English" ? "Phone number has been updated." : "Le numéro de téléphone a été mis à jour.")
            setStatus("default")
            console.log(response);
          } else {
            // post request
            const response = await postRequest('/reminders', {
              user_id: user,
              email_id: email,
              interval,
              lang,
              reminder_type: reminderType,
              sms: formatPhoneNumber(phone),
            });
            alert(lang === "English" ? "Phone number has been added." : "Le numéro de téléphone a été ajouté.")
            setStatus("submitted")
            console.log(response);
          }

        } else { }
      }

    } else if (reminderType === "email") {
      if (!email.includes('@')) {
        alert(lang === "English" ? "Not a valid email address" : "Adresse courriel invalide")
      } else {
        if (reminderData) {
          // patch request
          const response = await patchRequest(`/reminders/${user}`, {
            user_id: user,
            email_id: email,
            interval,
            lang,
            reminder_type: reminderType,
            sms: formatPhoneNumber(phone),
          });
          alert(lang === "English" ? "Email address has been updated." : "L'adresse e-mail a été mise à jour.")
          setStatus("default")
          console.log(response);
        } else {
          // post request
          const response = await postRequest('/reminders', {
            user_id: user,
            email_id: email,
            interval,
            lang,
            reminder_type: reminderType,
            sms: formatPhoneNumber(phone),
          });
          alert(lang === "English" ? "Email address has been added." : "L'adresse e-mail a été ajoutée.")
          setStatus("submitted")
          console.log(response);
        }
      }
    } else {
      if (reminderData) {
        // delete request
        const response = await deleteRequest(`/reminders/${user}`, {
          user_id: user,
        });
        alert(lang === "English" ? "Reminder has been deleted." : "Le rappel a été supprimé.")
        setStatus("submitted")
        console.log(response);
      } else {
        // alert
        alert(lang === "English" ? "No reminder exist." : "Aucun rappel n'existe.")
        setStatus("default")
      }
    }
  }

  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <h3>{lang === "English" ? 'Reminders' : 'Rappels'}</h3>
    <div style={{ margin: ".5rem auto" }}>{lang === "English" ? 'Do you wish to receive reminders to use this app?' : "Voulez-vous recevoir des rappels afin d'utiliser cette application?"}</div>
    <div>
      <Button variant='text' style={{ backgroundColor: confirmReminders ? 'var(--secondary-colour)' : "", color: confirmReminders ? 'white' : "", }} onClick={() => setConfirmReminders(true)}>{lang === "English" ? 'Yes' : "Oui"}</Button>
      {status === 'default' && <Button variant='text' color='secondary' style={{ backgroundColor: confirmReminders ? "" : "var(--secondary-colour)", color: confirmReminders ? "" : "white", }} onClick={() => { setConfirmReminders(false); disableReminder(); }}>{lang === "English" ? 'No' : 'Non'}</Button>}
      {status === 'submitted' && <Button disabled variant='text' color='secondary'>{lang === "English" ? 'No' : 'Non'}</Button>}
    </div>
    {confirmReminders && <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <div>
          {lang === "English"
            ? "Please enter your phone number/email"
            : "indiquez votre numéro de téléphone/courriel"}
        </div>
        <div>
          <select
            value={reminderType}
            onChange={(e) => setReminderType(e.currentTarget.value)}
          >
            <option value={"tel"}>
              {lang === "English" ? "Phone" : "Téléphone"}
            </option>
            <option value={"email"}>
              {lang === "English" ? "Email" : "Courriel"}
            </option>
            <option value={"none"}>
              {lang === "English" ? "No Reminder" : "Aucun rappel"}
            </option>
          </select>
          <input
            value={reminderType === "tel" ? phone : email}
            type={reminderType}
            onChange={(e) => {
              if (reminderType === "tel") {
                setPhone(e.currentTarget.value);
              } else {
                setEmail(e.currentTarget.value);
              }
            }}
          />
        </div>
      </div>

      {reminderType === "tel" &&
        <div style={{ "color": "red" }}>{lang === "English" ?
          'Your phone number will be provided to Twilio' :
          'Votre numéro de téléphone sera fourni à Twilio'}
        </div>}

      <div style={{ width: "100%" }}>
        <div>{lang === "English" ? "How Often?" : "Quand?"}</div>
        <select
          style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}
          onChange={(e) => setIntervalType(e.currentTarget.value)}
        >
          <option value={"daily"}>
            {lang === "English" ? "Daily" : "Chaque Jour"}
          </option>
          <option value={"weekly"}>
            {lang === "English" ? "Weekly" : "Chaque Semaine"}
          </option>
          <option value={"biweekly"}>
            {lang === "English"
              ? "Every 2 weeks"
              : "Chaque Deux Semaines"}
          </option>
        </select>
      </div>
      <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
        {status === 'default' && <Button className="submit-btn" variant='contained' onClick={() => {
          submitReminderData();
        }}
        >
          {lang === "English" ? "Submit" : "Soumettre"}
        </Button>
        }
        {status === "submitted" && (
          <Button
            disabled
            className="submit-btn submitted"
            variant="contained"
          >
            {lang === "English" ? "Submit" : "Soumettre"}
          </Button>
        )}

        <Button variant="text" onClick={() => setConfirmReminders(false)}>
          {lang === "English" ? "Close" : "Proche"}
        </Button>
      </div>
    </section>
    }
  </div>
}

export default Reminders
