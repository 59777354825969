import React, { useState, useEffect } from "react";
import TopBar from "../../../components/Admin/TopBar";
import Header from "../../../components/Home/Header";
import UserNav from "../../../components/UserDash/UserNav";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer
} from "recharts";
import { getRequest } from "../../../API/ApiHandler";

import "../UserPanel.css";
import IdleComponent from "../../../components/IdleComponent";
import HappyIndicatorFace from "../../../components/UI/IndicatorFaces/ChartHappyFace";
import NeutralIndicatorFace from "../../../components/UI/IndicatorFaces/ChartNeutralFace";
import PainIndicatorFace from "../../../components/UI/IndicatorFaces/ChartPainFace";
import Subheader from "../../../components/UI/Subheader/Subheader";

//Get value of language from local storage
let lang = localStorage.getItem("language");

const PainAvg = (props) => {
  const [user, setUser] = useState();
  const [logs, setLogs] = useState([]);
  const [logsExport, setLogsExport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState();
  const [date1, setDate1] = useState("default");
  const [date2, setDate2] = useState("default");
  const [limited, setLimited] = useState([]);
  const [navTo, setNavTo] = useState("");

  const handleStepperNav = (to) => {
    props.history.push(to);
  };

  //get current user's id
  const getUser = async () => {
    let response = await getRequest("/auth/users/current/me");
    let user = "";
    if (response) {
      user = response.data.id;
      setUser(user);
      getLogs(user);
    }
  };

  //get that user's logs
  const getLogs = async (user) => {
    const data = await getRequest(`/logs/${user}`);
    if (data) {
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      let updatedLog = data.map((log) => {
        const options = { timeStyle: "short", timeZone: "UTC" };
        const time = new Date(log.createdAt).toLocaleTimeString(
          "en-CA",
          options
        );
        const date = new Date(log.createdAt).toLocaleDateString("en-CA");
        log.date = date;
        log.time = time;
        return log;
      });
      setLogs(updatedLog);
      getData(updatedLog);
    }
    setIsLoading(false);
  };

  //create map from logs of dates, pain levels, and other treatments
  const getData = (updatedLog) => {
    console.log(updatedLog.step_one);
    const exportList = updatedLog.map((el) => {
      if (el.step_one !== null) {
        let log = {
          date: el.date,
          level: el.step_one ? JSON.parse(el.step_one).pain_level : "",
          treatment: el.step_one
            ? JSON.parse(el.step_one).other_treatments
            : "",
          med: el.step_one ? JSON.parse(el.step_one).selected_meds : ""
        };
        return log;
      }
    });
    setLogsExport(exportList);
    removeNull(exportList);
  };

  //remove any null/undefined entries from the above
  const removeNull = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined;
    });
    setLogsExport(exportList);
    firstTen(exportList);
    getDates(exportList);
    return exportList;
  };

  //use ten most recent logs to populate graph
  const firstTen = (exportList) => {
    if (exportList.length <= 10) {
      setLimited(exportList);
    } else {
      let firstTen = [];
      for (var i = 0; i < 10; i++) {
        firstTen.push(exportList[i]);
      }
      setLimited(firstTen);
    }
  };

  //get just the dates from those logs for dropdown
  const getDates = (exportList) => {
    const datelist = exportList.map((el) => {
      if (el !== null && el.length !== 0) {
        let dates = {
          date: el.date
        };
        return dates;
      }
    });
    const noDups = [
      ...new Map(datelist.map((item) => [JSON.stringify(item), item])).values()
    ].reverse();
    setDates(noDups);
    setIsLoading(false);
  };

  //state change of first dropdown
  const handleDate1 = (e) => {
    setDate1(e.target.value);
  };

  useEffect(() => {
    if (date1 === "default") {
      setDate2("default");
    } else {
      if (date2 === "default") {
        setDate2(dates[0].date);
      } else {
        onClick(date1, date2, logsExport);
      }
    }
  }, [date1]);

  useEffect(() => {
    if (date2 === "default") {
      firstTen(logsExport);
    } else {
      onClick(date1, date2, logsExport);
    }
  }, [date2]);

  //state change of second dropdown
  const handleDate2 = (e) => {
    setDate2(e.target.value);
    onClick(date1, date2, logsExport);
  };

  //onClick to load logs in range of selected dates
  const onClick = (d1, d2, logs) => {
    if (d1 === "default" || d2 === "default") {
      return;
    }
    let dateRange = [];
    var d1 = Date.parse(d1);
    var d2 = Date.parse(d2);
    logs.map((el) => {
      var logDate = Date.parse(el.date);
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el);
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el);
      }
    });
    setLimited(dateRange);
  };

  const Emoji = (props) => (
    <span
      className={`emoji ${props.indicator}`}
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
    >
      {props.symbol}
    </span>
  );

  useEffect(() => {
    setIsLoading(true);
    getUser();
    if (user) getLogs();
  }, [user]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let level = "";
      if (payload[0].value <= 1) {
        level = lang === "English" ? "No Pain" : "Pas de douleur";
      } else if (payload[0].value <= 3) {
        level = lang === "English" ? "Moderate Pain" : "Douleur moyenne";
      } else {
        level = lang === "English" ? "Extreme Pain" : "Douleur extrême";
      }

      let meds = [];
      for (var i = 0; i < payload[0].payload.med.length; i++) {
        lang === "English"
          ? meds.push(" " + payload[0].payload.med[i].name)
          : meds.push(" " + payload[0].payload.med[i].fr_name);
      }

      let treatments = [];
      for (var i = 0; i < payload[0].payload.treatment.length; i++) {
        lang === "English"
          ? treatments.push(" " + payload[0].payload.treatment[i].name)
          : treatments.push(" " + payload[0].payload.treatment[i].fr_name);
      }

      return (
        <div className="painAvg-custom-tooltip">
          <p>{`${"Date"} : ${payload[0].payload.date}`}</p>
          <p>{`${lang === "English" ? "Pain Average" : "Douleur Moyenne"} : ${
            payload[0].value
          } => ${level}`}</p>
          <p>{`${
            lang === "English"
              ? "Prescribed Arthritis Medication Used"
              : "Médicaments contre l'arthrite prescrits utilisés"
          } : ${
            meds.length !== 0 ? meds : lang === "English" ? "None" : "Aucun(e)"
          }`}</p>
          <p>{`${
            lang === "English"
              ? "Other Treatments Used"
              : "Autres traitements utilisés"
          } : ${
            treatments.length !== 0
              ? treatments
              : lang === "English"
              ? "None"
              : "Aucun(e)"
          }`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <IdleComponent history={props.history} />
      <div className="user-container">
        <div className="page-header">
          <Header
            current={props}
            handleNav={handleStepperNav}
            setNavTo={setNavTo}
          />
        </div>
        <div className="page-subheader">
          <Subheader
            title={lang == "English" ? "User Dashboard" : "Tableau de bord"}
          />
        </div>

        <div style={{ "margin-top": "4rem", "margin-left": "4rem" }}>
          <UserNav />
          {/* <div className="user-body"> */}
          <div className="captions" style={{ "margin-top": "2rem" }}>
            <h3>
              <b>
                {lang === "English"
                  ? "Pain over time"
                  : "Douleur à travers le temps"}
              </b>
            </h3>
            <p>
              {lang === "English"
                ? "Here you can review how much pain on average you have had because of your arthritis."
                : "Ici vous pouvez évaluer la douleur moyenne que vous avez ressentie à cause de votre arthrite"}
            </p>
            <p>
              {lang === "English"
                ? "The values on this graph are taken directly from your past sessions using the JIA Option Map."
                : "Les valeurs de ce graphique sont tirées directement de vos sessions passées dans la Carte d'options en AJI."}
            </p>
            <p>
              {lang === "English"
                ? "You can hover over any point on the graph to see the date at which you put this information, your pain, and the treatments you were using at that time!"
                : "Vous pouvez survoler n'importe quel point du graphique pour voir la date à laquelle vous avez mis cette information, votre douleur et les traitements que vous utilisiez à ce moment-là!"}
            </p>
          </div>

          <div className={"chartWlegend"}>
            <div className="legend">
              <h5>
                <b>{lang === "English" ? "Legend" : "Légende"}</b>
              </h5>
              <div className="emojis">
                <div className={"levels"}>
                  <PainIndicatorFace />{" "}
                  <h6>{`${
                    lang === "English"
                      ? "4-5 => Extreme Pain"
                      : "4-5 => Douleur extrême"
                  }`}</h6>
                </div>
                <div className={"levels"}>
                  <NeutralIndicatorFace />{" "}
                  <h6>{`${
                    lang === "English"
                      ? "2-3 => Moderate Pain"
                      : "2-3 => Douleur moyenne"
                  }`}</h6>
                </div>
                <div className={"levels"}>
                  <HappyIndicatorFace />{" "}
                  <h6>{`${
                    lang === "English" ? "1 => No Pain" : "1 => Pas de douleur"
                  }`}</h6>
                </div>
              </div>
            </div>

            <div className={"chartWrapper"}>
              <div className={"intervalDropdown"}>
                <div
                  className={
                    lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"
                  }
                >
                  <select
                    disabled={isLoading}
                    onChange={handleDate1}
                    className="select_date"
                  >
                    <option value="default">
                      {lang === "English"
                        ? "-- Select a Date --"
                        : "-- Sélectionnez une date --"}
                    </option>
                    {dates !== undefined &&
                      dates.length !== 0 &&
                      dates.map((list) => (
                        <option
                          key={list.date}
                          value={list.date}
                          selected={list.date === date1}
                        >
                          {list.date}
                        </option>
                      ))}
                  </select>
                </div>

                <div
                  className={
                    lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"
                  }
                >
                  <select
                    disabled={isLoading}
                    onChange={handleDate2}
                    className="select_date"
                  >
                    <option value="default">
                      {lang === "English"
                        ? "-- Select a Date --"
                        : "-- Sélectionnez une date --"}
                    </option>
                    {dates !== undefined &&
                      dates.length !== 0 &&
                      dates.map((list) => (
                        <option
                          key={list.date}
                          value={list.date}
                          selected={list.date === date2}
                        >
                          {list.date}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <ResponsiveContainer width="95%" height={400}>
                <LineChart
                  className="chart"
                  data={limited}
                  margin={{ top: 5, right: 50, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    label={{ value: "Date", position: "bottom", offset: 0 }}
                    reversed
                  />
                  <YAxis
                    dataKey="level"
                    tickCount={5}
                    domain={[1, 5]}
                    label={{
                      value:
                        lang === "English"
                          ? "Pain Average"
                          : "Moyennes de la douleur",
                      position: "insideCenter",
                      angle: -90
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line type="monotone" dataKey="level" stroke="#296d98" />
                  <ReferenceLine
                    y={2.5}
                    strokeDasharray="5 5"
                    stroke="#000000"
                  />

                  <defs>
                    <linearGradient id="colorGY" x1="0" y1="1" x2="0" y2="0">
                      <stop offset="5%" stopColor="#03c04a" stopOpacity={1} />
                      <stop offset="95%" stopColor="#effd5f" stopOpacity={1} />
                    </linearGradient>
                  </defs>

                  <defs>
                    <linearGradient id="colorYR" x1="0" y1="1" x2="0" y2="0">
                      <stop offset="5%" stopColor="#effd5f" stopOpacity={1} />
                      <stop offset="95%" stopColor="#e3242b" stopOpacity={1} />
                    </linearGradient>
                  </defs>

                  <ReferenceArea
                    y1={1}
                    y2={2}
                    fill="url(#colorGY)"
                    opacity={1}
                  />
                  <ReferenceArea y1={2} y2={3} fill="#effd5f" opacity={1} />
                  <ReferenceArea
                    y1={3}
                    y2={5}
                    fill="url(#colorYR)"
                    opacity={1}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default PainAvg;
