import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Modal
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { logout } from "./AuthenticationService";

const IdleComponent = (props) => {
  const idleTimeout = 30 * 60000;
  let idleTimer = null;
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  let lang = localStorage.getItem("language");

  const executeLogout = async () => {
    // Add a save function here
    if (props.saveHandler) {
      await props.saveHandler();
    }
    logout();
    props.history.push("/", { timeout: true });
  };

  const onAction = (e) => {
    // console.log('onAction');
    if (!isTimedOut) setIsTimedOut(false);
  };

  const onActive = (e) => {
    // console.log('onActive');
    if (!isTimedOut) setIsTimedOut(false);
  };

  const onIdle = async (e) => {
    if (isTimedOut) {
      executeLogout();
    } else {
      setShowModal(true);
      idleTimer.reset();
      setIsTimedOut(true);
    }
  };

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onActive={onActive}
        onAction={onAction}
        onIdle={onIdle}
        debounce={250}
        timeout={idleTimeout}
      />

      <Modal open={showModal}>
        <Box
          style={{
            position: "absolute",
            top: "120px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            border: "2px solid #000",
            boxShadow: 24,
            padding: 4,
            backgroundColor: "white",
            borderRadius: "10px"
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {lang === "English"
              ? "Do you want to continue or logout?"
              : "•	Voulez-vous continuer ou vous déconnecter?"}
          </Typography>
          <Divider></Divider>
          <Typography
            id="modal-modal-description"
            style={{
              marginTop: 2
            }}
          >
            {lang === "English" ? (
              <>
                You have been idle for 30 minutes <br /> Would you like to
                continue your session or log out? <br /> Your data will be saved
                in the event of a logout.
              </>
            ) : (
              <>
                Vous avez été inactif pendant 30 minutes. <br /> Voulez-vous
                continuer votre session ou vous déconnecter? <br /> Vos données
                seront enregistrées en cas de déconnexion.
              </>
            )}
          </Typography>
          <Divider></Divider>
          <Container
            style={{
              display: "flex",
              justifyContent: "end",
              gap: 2,
              marginTop: 5
            }}
          >
            <Button variant="contained" color="primary" onClick={executeLogout}>
              {lang === "English" ? "Logout" : "Se déconnecter"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setShowModal(false);
                idleTimer.reset();
              }}
            >
              {lang === "English" ? "Continue" : "Continuez"}
            </Button>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default IdleComponent;
