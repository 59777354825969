/**
 * @file
 * Component for step 3 "Review & select treatment options"
 * Contains the "My Favourites" tab section
 * @author <akel.ms93@gmail.com>
 */

import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import TipCard from "../TipCard";
import TreatmentCard from "../TreatmentCard";

const Favourites = ({
  favs,
  setFavs,
  favTips,
  setFavTips,
  treatments,
  tips
}) => {
  const [favsList, setFavsList] = useState([]);
  const [favTipsList, setFavTipsList] = useState([]);

  useEffect(() => {
    // Get the treatments which are in the favourites list
    if (treatments && favs) {
      let filteredList = [];
      favs.forEach((fav) => {
        treatments.forEach((treatment) => {
          if (treatment.id === fav.id) filteredList.push(treatment);
        });
      });
      setFavsList(filteredList);
    }
  }, [treatments, favs]);

  useEffect(() => {
    // Get the tips which are in the favourites list
    if (tips && favTips) {
      let filteredTipList = [];
      favTips.forEach((fav) => {
        tips.forEach((tip) => {
          if (tip.id === fav.id) filteredTipList.push(tip);
        });
      });
      setFavTipsList(filteredTipList);
    }
  }, [tips, favTips]);

  // Treatment card list
  const treatmentCards =
    favsList.length &&
    favsList.map((treatment) => (
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={favs}
        setSelected={setFavs}
        icon="fav"
      />
    ));

  // Treatment card list
  const tipCards =
    favTipsList.length &&
    favTipsList.map((tip) => (
      <TipCard
        key={tip.id}
        tip={tip}
        selected={favTips}
        setSelected={setFavTips}
        icon="fav"
      />
    ));

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  return (
    <React.Fragment>
      <div className="notice plan-top-notice">
        <h5>
          {lang === "English"
            ? "Please review your saved treatments & tips below. Add or remove any favourites."
            : "Veuillez réviser les traitements et trucs enregistrés ci-dessous. N’hésitez pas à ajouter et à supprimer des favoris."}
        </h5>
      </div>
      <div className="review-content">
        <h4 className="mb-0 title-padding fav-heading">
          {lang === "English" ? "Treatments" : "Traitements"}
        </h4>
        <Row className="padding-class">
          {favsList.length ? (
            treatmentCards
          ) : (
            <h1>
              {lang === "English"
                ? "Your favourites treatment list is empty"
                : "Votre liste de soins favoris est vide."}
            </h1>
          )}
        </Row>
        <h4 className="mb-0 title-padding fav-heading">
          {lang === "English" ? "Tips" : "trucs"}
        </h4>
        <Row className="padding-class">
          {favTipsList.length ? (
            tipCards
          ) : (
            <h1>
              {lang === "English"
                ? "Your favourites tip list is empty"
                : "Votre liste de trucs favoris est vide."}
            </h1>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Favourites;
