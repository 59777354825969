import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';

const Subcategory = ({subcategory, subNo}) => {
  const [collapseStates, setCollapse] = useState({
    tab:false,
    q1: false,
    q2: false,
    q3: false,
    q4: false
  });
  

  //Get value of language from local storage
  let lang = localStorage.getItem("language")
  
  useEffect(() => {
    if (subNo === 1 ) setCollapse(true)
  }, [subNo])

 const toggleCollapse = (key) => {
  setCollapse((prevStates) => ({
      ...prevStates,
      [key]: !prevStates[key], // Toggle only the clicked collapse
    }));
  };  

    // Function to parse text and convert URLs into clickable links
    const parseTextToLinks = (text) => {
      if (!text) return null; // Handle empty or null input
  
      // Split text into lines
      const lines = text.split('\n');
  
      // Regular expression to match URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;
  
      // Map each line to a React element
      return lines.map((line, index) => {
        const parts = line.split(urlRegex); // Split line into text and URLs
        return (
          <p key={index} className= "subcategory-p">
            {parts.map((part, idx) =>
              urlRegex.test(part) ? (
                <a
                  key={idx}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: '1 rem', color: 'blue', textDecoration: 'underline' }}
                >
                  {part}
                </a>
              ) : (
                part // Non-URL parts
              )
            )}
          </p>
        );
      });
    };
    
  return (
    <React.Fragment>
      {subcategory.name ?
        <div className="box-container-numbered">
          <div className="obvious" onClick={() => toggleCollapse('tab')}>
            <h3 className="study-numbered-subheading text-big">{lang === "English" ? subcategory.name : subcategory.fr_name}
              <span className="collapsed-icon text-medium">{collapseStates.tab ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
            </h3>
          </div>
          <Collapse isOpen={collapseStates.tab} style={{marginBottom: '2rem', padding: '1rem'}}>
            {/* Heading 1 Collapse */}
            {subcategory.q1 && (
              <div>
                <div className="research_heading" onClick={() => toggleCollapse('q1')}>
                  <h5 className='text-medium'>
                    {lang === "English" ? subcategory.q1 : subcategory.q1_fr} 
                    <span className="collapsed-icon text-small">{collapseStates.q1 ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
                  </h5>
                </div>
                <Collapse isOpen={collapseStates.q1}>
                <div className="treatment_details_study_container">
                  <ul>{lang === "English" ? subcategory.a1.split('\n').map((line, index) =>(
                    <li key={index} className='text-small-unbold'>&#8226; {line}</li>
                    ))
                  : subcategory.a1_fr.split('\n').map((line, index) =>(
                    <li key={index} className= 'text-small-unbold'>&#8226; {line}</li>
                    ))}
                  </ul>
                  {subcategory.a1_ext && 
                  lang === "English" ? parseTextToLinks(subcategory.a1_ext)
                  : parseTextToLinks(subcategory.a1_ext_fr)}
                </div>
                </Collapse>
              </div>
            )}
            {/* Add more lines for answer later */}
            {/* Heading 2 Collapse */}
            {subcategory.q2 && (
              <div>
                <div className="research_heading" onClick={() => toggleCollapse('q2')}>
                  <h5 className='text-medium'>
                    {lang === "English" ? subcategory.q2 : subcategory.q2_fr} 
                    <span className="collapsed-icon text-small">{collapseStates.q2 ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
                  </h5>
                </div>
                <Collapse isOpen={collapseStates.q2}>
                <div className="treatment_details_study_container">
                  <ul>{lang === "English" ? subcategory.a2.split('\n').map((line, index) =>(
                    <li key={index} className='text-small-unbold'>&#8226; {line}</li>
                    ))
                  : subcategory.a2_fr.split('\n').map((line, index) =>(
                    <li key={index} className= 'text-small-unbold'>&#8226; {line}</li>
                    ))}
                  </ul>
                  {subcategory.a2_ext && 
                  lang === "English" ? parseTextToLinks(subcategory.a2_ext)
                  : parseTextToLinks(subcategory.a2_ext_fr)}
                </div>
                </Collapse>
              </div>
            )}
            {/* Heading 3 Collapse */}
            {subcategory.q3 && (
              <div>
                <div className="research_heading" onClick={() => toggleCollapse('q3')}>
                  <h5 className='text-medium'>
                    {lang === "English" ? subcategory.q3 : subcategory.q3_fr} 
                    <span className="collapsed-icon text-small">{collapseStates.q3 ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
                  </h5>
                </div>
                <Collapse isOpen={collapseStates.q3}>
                <div className="treatment_details_study_container">
                  <ul>{lang === "English" ? subcategory.a3.split('\n').map((line, index) =>(
                    <li key={index} className='text-small-unbold'>&#8226; {line}</li>
                    ))
                  : subcategory.a3_fr.split('\n').map((line, index) =>(
                    <li key={index} className= 'text-small-unbold'>&#8226; {line}</li>
                    ))}
                  </ul>
                  {subcategory.a3_ext && 
                  lang === "English" ? parseTextToLinks(subcategory.a3_ext)
                  : parseTextToLinks(subcategory.a3_ext_fr)}
                </div>
                </Collapse>
              </div>
            )}
            {/* Heading 4 Collapse */}
            {subcategory.q4 && (
              <div>
                <div className="research_heading" onClick={() => toggleCollapse('q4')}>
                  <h5 className='text-medium'>
                    {lang === "English" ? subcategory.q4 : subcategory.q4_fr} 
                    <span className="collapsed-icon text-small">{collapseStates.q4 ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
                  </h5>
                </div>
                <Collapse isOpen={collapseStates.q4}>
                <div className="treatment_details_study_container">
                  <ul>{lang === "English" ? subcategory.a4.split('\n').map((line, index) =>(
                    <li key={index} className='text-small-unbold'>&#8226; {line}</li>
                    ))
                  : subcategory.a4_fr.split('\n').map((line, index) =>(
                    <li key={index} className= 'text-small-unbold'>&#8226; {line}</li>
                    ))}
                  </ul>
                  {subcategory.a4_ext && 
                  lang === "English" ? parseTextToLinks(subcategory.a4_ext)
                  : parseTextToLinks(subcategory.a4_ext_fr)}
                </div>
                </Collapse>
              </div>
            )}
          </Collapse>
        </div>
      : <>
          <div className='research-subheadings'>
          </div>
        </>

      }
    </React.Fragment>
  )
}

export default Subcategory