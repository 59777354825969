import React, { useEffect } from 'react';
import { deleteRequest } from '../../API/ApiHandler';
import ButtonVals from '../ButtonVals';

const TipButton = ({tip, selectedTips, setSelectedTips, setSaved, setSaveStatus}) => {

  const clickHandler = ev => {
    let isSelected = ButtonVals(ev);
    if (isSelected) {
      let {id, name} = tip
      let _tip = {id, name}
      setSelectedTips([...selectedTips, _tip])
    } else {
      setSelectedTips(selectedTips.filter(item => item.name !== tip.name))
      let tipId = {tip_id: tip.id}
      deleteRequest("/userFavouriteTips", tipId)
    }
    setSaved(false)
    setSaveStatus('default')
  }

  //process the tips
  const processString = (str) => {
    const removeKeywords = ["tips", "Conseils", "Trucs","pour", "les", "le"];
    let processed = str;
    removeKeywords.forEach((keyword) => {
      processed = processed.replace(new RegExp(`\\b${keyword}\\b`, 'gi'), '').trim(); 
    });
    if (processed === "l’école") {
      return "École";
    }
    if (processed.length > 0) {
      processed = processed.charAt(0).toUpperCase() + processed.slice(1);
    }
    return processed; 
  };

  const highlightSelectedTips = () => {
    selectedTips.forEach(item => {
      if(item.name === tip.name) {
        let button = document.getElementById(`${item.name}`);
        button.setAttribute("data-selected", true)
        button.style.backgroundColor = "#10434F";
        button.style.color = "#FFF";
      }
    })
  }

  useEffect(() => {
    highlightSelectedTips()
  }, [])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  return(
    <button id={tip.name} onClick={clickHandler}>
      {lang === "English" ? processString(tip.name) :processString(tip.fr_name)}
    </button>
  )
}

export default TipButton