import React, { useState, useEffect } from "react";
import { getRequest } from "../../API/ApiHandler";
import SymptomButton from "./SymptomButton";
const Symptoms = ({
  selectedSyms,
  setSelectedSyms,
  setSaved,
  setSaveStatus
}) => {
  const [symptoms, setSymptoms] = useState([]);

  let lang = localStorage.getItem("language");

  const getSymptoms = async () => {
    await getSelectedSyms();
    let { data } = await getRequest("/symptoms");
    setSymptoms(data);
  };

  const getSelectedSyms = async () => {
    let syms = await getRequest(`/userSymptoms/user`);

    if (syms) {
      let { data } = syms;
      if (data.length > 0) {
        const symptomNames = data.map((symptom) => symptom.name);
        sessionStorage.setItem(
          "selectedSymptoms",
          JSON.stringify(symptomNames)
        );
      }
    }
  };

  useEffect(() => {
    getSymptoms();
  }, []);

  const symptomsContent =
    symptoms.length > 0 &&
    symptoms.map((sym) => (
      <SymptomButton
        key={sym.id}
        sym={sym}
        selectedSyms={selectedSyms}
        setSelectedSyms={setSelectedSyms}
        setSaved={setSaved}
        setSaveStatus={setSaveStatus}
      />
    ));

  return (
    <div className="StepTwo-div other">
      <h4 className="Step-subHeading">
        {lang === "English"
          ? "Which symptoms do you wish to manage?"
          : "Quels symptômes souhaitez-vous gérer?"}
      </h4>
      <div>
        {symptomsContent.length ? (
          symptomsContent
        ) : (
          <h4 style={{ padding: "1.5rem" }}>
            {lang === "English"
              ? "There are currently no items listed under this classification"
              : "Il n'y a actuellement aucun article répertorié sous cette classification"}
          </h4>
        )}
      </div>
    </div>
  );
};

export default Symptoms;
