import React, { useEffect, useState } from "react";
import { NavLink as RRNav } from "react-router-dom";
import { Button } from "reactstrap";
import { postRequest } from "../../API/ApiHandler";
import Footer from "../../components/Footer";
import Header from "../../components/Home/Header";
import IdleComponent from "../../components/IdleComponent";
import Symptoms from "../../components/Preparation/Symptoms";
import Tips from "../../components/Preparation/Tips";
import SaveButton from "../../components/UI/SaveButton";
import Subheader from "../../components/UI/Subheader/Subheader";
import "./Preparation.css";

const Decision = (props) => {
  const [selectedSyms, setSelectedSyms] = useState([]);
  const [selectedTips, setSelectedTips] = useState([]);
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const [navTo, setNavTo] = useState("");
  const [navBy, setNavBy] = useState("");
  const [saved, setSaved] = useState(true);
  const [saveStatus, setSaveStatus] = useState("default");

  let lang = localStorage.getItem("language");

  useEffect(() => {
    const storedSymptoms = sessionStorage.getItem("selectedSymptoms");

    if (storedSymptoms) {
      const symptomNames = JSON.parse(storedSymptoms);

      setSelectedSyms((prevSelectedSyms) => {
        const existingNames = new Set(
          prevSelectedSyms.map((symptom) => symptom.name)
        );
        const newSymptoms = symptomNames
          .filter((name) => !existingNames.has(name))
          .map((name) => ({ name }));

        const updatedSelectedSyms = [...prevSelectedSyms, ...newSymptoms];
        return updatedSelectedSyms;
      });
    }
  }, []);

  useEffect(() => {
    const symptomNames = selectedSyms.map((symptom) => symptom.name);
    sessionStorage.setItem("selectedSymptoms", JSON.stringify(symptomNames));
  }, [selectedSyms]);

  // Save values when save button is clicked
  const saveHandler = async () => {
    await Promise.all([saveSelectedSymptoms(), saveSelectedTips()]).then(
      async (data) => {
        let failed = data.indexOf() !== -1;
        if (!failed) {
          // await handleRecommendations()
          updateLog();
          setSaved(true);
          setSaveStatus("success");
          props.history.push(`/questionnaire`);
        } else {
          setSaveStatus("default");
        }
      }
    );
  };

  const updateLog = () => {
    // List of Selected Symptoms
    const symptoms = selectedSyms.map((sym) => {
      let symName = { name: sym.name };
      return symName;
    });

    // List of Selected Tips
    const tips = selectedTips.map((tip) => {
      let tipName = { name: tip.name };
      return tipName;
    });

    // const data = {
    //     selectedSyms: symptoms,
    //     selectedTips: tips
    // }
    // updateLogStepOne(data)
  };

  const saveSelectedSymptoms = () => {
    const input = { selectedSyms: selectedSyms };
    return postRequest("/userSymptoms", input, setSaveStatus);
  };

  const saveSelectedTips = () => {
    const input = { selectedTips: selectedTips };
    return postRequest("/userTips", input, setSaveStatus);
  };

  const handleStepperNav = (to) => {
    if (saved) {
      props.history.push(to);
    } else {
      setNavBy("stepper");
      setDisplayNavDialog(true);
    }
  };

  return (
    <div className="wrapper">
      <IdleComponent
        history={props.history}
        saveHandler={saveHandler}
        status={saveStatus}
      />
      <div className="page-header">
        <Header
          current={props}
          handleNav={handleStepperNav}
          setNavTo={setNavTo}
        />
      </div>
      <div className="page-subheader">
        <Subheader title={lang === "English" ? "Symptoms & treatments" : "Symptômes et traitement"} />
      </div>

      <div className="body-container">
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/intro">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button className="next-btn" tag={RRNav} to="/questionnaire">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
        <div class="box-container-numbered">
          <div className="StepTwo-div other">
            <p className="numbered-subheading">
              {lang === "English"
                ? "Please choose the symptoms you wish to manage and the types of activities you wish to participate in more fully"
                : "Veuillez choisir les symptômes que vous souhaitez gérer et les types d'activités auxquelles vous souhaitez participer plus pleinement"}
            </p>

            <Symptoms
              selectedSyms={selectedSyms}
              setSelectedSyms={setSelectedSyms}
              setSaved={setSaved}
              setSaveStatus={setSaveStatus}
            />

            <Tips
              selectedTips={selectedTips}
              setSelectedTips={setSelectedTips}
              setSaved={setSaved}
              setSaveStatus={setSaveStatus}
            />
            <br></br>
            <br></br>
            <h6 class="center">
              {lang === "English"
                ? "The choices you make on this page will change the questions that will be shown on the next page and will decide which treatments and tips will be shown to you in Step 3."
                : "Les choix que vous faites sur cette page modifieront les questions qui seront affichées sur la page suivante et décideront quels traitements et Trucs vous seront présentés à l'étape 3."}
            </h6>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/intro">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button className="next-btn" tag={RRNav} to="/questionnaire">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

//   const saveSelectedSymptoms = () => {
//     const input = { selectedSyms: selectedSyms };
//     return postRequest("/userSymptoms", input, setSaveStatus);
//   };

//   const saveSelectedTips = () => {
//     const input = { selectedTips: selectedTips };
//     return postRequest("/userTips", input, setSaveStatus);
//   };

//   const handleStepperNav = (to) => {
//     if (saved) {
//       props.history.push(to);
//     } else {
//       setNavBy("stepper");
//       setDisplayNavDialog(true);
//     }
//   };

//   return (
//     <div className="wrapper">
//       <IdleComponent
//         history={props.history}
//         saveHandler={saveHandler}
//         status={saveStatus}
//       />
//       <Header
//         current={props}
//         handleNav={handleStepperNav}
//         setNavTo={setNavTo}
//       />
//       <Subheader
//         title={
//           lang === "English"
//             ? " Treatments"
//             : "Symptômes et Traitements"
//         }
//       />

//       <div className="body-container">
//         <div class="box-container-numbered">
//           <div className="StepTwo-div other">
//             <p className="numbered-subheading">
//               {lang === "English"
//                 ? "Please choose the symptoms you wish to manage and the types of activities you wish to participate in more fully"
//                 : "Veuillez choisir les symptômes que vous souhaitez gérer et les types d'activités auxquelles vous souhaitez participer plus pleinement"}
//             </p>

//             <Symptoms
//               selectedSyms={selectedSyms}
//               setSelectedSyms={setSelectedSyms}
//               setSaved={setSaved}
//               setSaveStatus={setSaveStatus}
//             />

//             <Tips
//               selectedTips={selectedTips}
//               setSelectedTips={setSelectedTips}
//               setSaved={setSaved}
//               setSaveStatus={setSaveStatus}
//             />
//             <br></br>
//             <br></br>
//             <h6 class="center">
//               {lang === "English"
//                 ? "The choices you make on this page will change the questions that will be shown on the next page and will decide which treatments and tips will be shown to you in Step 3."
//                 : "Les choix que vous faites sur cette page modifieront les questions qui seront affichées sur la page suivante et décideront quels traitements et Trucs vous seront présentés à l'étape 3."}
//             </h6>
//           </div>
//         </div>
//       </div>

//       <NavigationButton
//         className="next-btn"
//         handleNavigation={saveHandler}
//         btnText={lang === "English" ? "Next" : "Suivant(e)"}
//       />

//       <Footer />
//     </div>
//   );
// };

export default Decision;
