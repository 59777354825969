/**
 * @file
 * Component for step 4 "Make your plan
 * Contains the "Choose the treatments in your plan" tab section
 * Fetches treatments in the user plan
 * @author <akel.ms93@gmail.com>
 */

import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { getRequest } from "../../API/ApiHandler";
import TipCard from "../TipCard";
import TreatmentCard from "../TreatmentCard";

const PlanList = ({
  favs,
  treatments,
  favTips,
  tips,
  plan,
  setPlan,
  tipPlan,
  setTipPlan,
  setSaved,
  setSaveStatus
}) => {
  const [scList, setScList] = useState([]);
  const [hcpList, setHcpList] = useState([]);
  const [tipList, setTipList] = useState([]);

  const getUserPlan = async () => {
    let data = await getRequest(`/userPlans/user`);
    if (data) {
      const ids = data.map((treatment) => {
        return { id: treatment.id };
      });
      setPlan(ids);
    }
  };
  const getUserTipPlan = async () => {
    let data = await getRequest(`/userTipPlans/user`);
    if (data) {
      const ids = data.map((tip) => {
        return { id: tip.id };
      });
      setTipPlan(ids);
    }
  };

  const handleSC = () => {
    let filteredList = [];
    favs.forEach((fav) => {
      treatments.forEach((treatment) => {
        if (treatment.id === fav.id && treatment.supervision === "SC")
          filteredList.push(treatment);
      });
    });
    setScList(filteredList);
  };

  const handleHCP = () => {
    let filteredList = [];
    favs.forEach((fav) => {
      treatments.forEach((treatment) => {
        if (treatment.id === fav.id && treatment.supervision === "HCP")
          filteredList.push(treatment);
      });
    });
    setHcpList(filteredList);
  };

  const handleTIP = () => {
    let filteredList = [];
    favTips.forEach((favTip) => {
      tips.forEach((tip) => {
        if (tip.id === favTip.id) filteredList.push(tip);
      });
    });
    setTipList(filteredList);
  };

  useEffect(() => {
    getUserPlan();
    getUserTipPlan();
  }, []);

  useEffect(() => {
    if (treatments && favs) handleSC();
    if (treatments && favs) handleHCP();
  }, [treatments, favs]);

  useEffect(() => {
    if (tips && favTips) handleTIP();
  }, [tips, favTips]);

  // Treatment card list
  const treatmentCardsSC =
    scList.length &&
    scList.map((treatment) => (
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={plan}
        icon="checkbox"
        setPlan={setPlan}
        setSaved={setSaved}
        setSaveStatus={setSaveStatus}
      />
    ));

  const treatmentCardsHCP =
    scList.length &&
    hcpList.map((treatment) => (
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={plan}
        icon="checkbox"
        setPlan={setPlan}
        setSaved={setSaved}
        setSaveStatus={setSaveStatus}
      />
    ));

  // Tip card list
  const tipCards =
    tipList.length &&
    tipList.map((tip) => (
      <TipCard
        key={tip.id}
        tip={tip}
        selected={tipPlan}
        setSelected={setTipPlan}
        icon="checkbox"
        setPlan={setTipPlan}
        setSaved={setSaved}
        setSaveStatus={setSaveStatus}
      />
    ));

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  return (
    <div className="box-container-numbered plan-container">
      <p className="numbered-subheading">
        {lang === "English"
          ? "1. Choose the treatments in your plan"
          : "1. Choisissez les traitements à inclure dans votre plan"}
      </p>
      <div className="notice plan-top-notice">
        <h5>
          {lang === "English"
            ? "Please review your favourites and choose the treatments you wish to try now or talk about with your health care team."
            : "Veuillez consulter vos favoris et choisir les traitements que vous souhaitez essayer maintenant ou dont vous voulez parler avec votre équipe soignante."}
        </h5>
      </div>
      <div className="review-content">
        <div className="title-padding">
          <h4>
            {lang === "English"
              ? "Treatments you wish to try now"
              : "Traitements que vous pourriez essayer dès maintenant"}
          </h4>
        </div>
        <Row className="padding-class">
          {scList.length ? (
            treatmentCardsSC
          ) : (
            <h1>
              {lang === "English"
                ? "Your favourites list is empty"
                : "Votre liste de favoris est vide"}
            </h1>
          )}
        </Row>

        <div className="title-padding">
          <h4>
            {lang === "English"
              ? "Treatments you wish to talk about with your health care team before trying"
              : "Traitements dont vous pourriez parler avec votre équipe soignante avant de les essayer"}
          </h4>
        </div>
        <Row className="padding-class">
          {scList.length ? (
            treatmentCardsHCP
          ) : (
            <h1>
              {lang === "English"
                ? "Your favourites list is empty"
                : "Votre liste de favoris est vide"}
            </h1>
          )}
        </Row>

        <div className="title-padding">
          <h4>
            {lang === "English"
              ? "Tips you wish to try now"
              : "Trucs que vous souhaitez réviser"}
          </h4>
        </div>
        <Row className="padding-class">
          {tipList.length ? (
            tipCards
          ) : (
            <h1>
              {lang === "English"
                ? "Your favourites list is empty"
                : "Votre liste de favoris est vide"}
            </h1>
          )}
        </Row>
      </div>
      <div className="notice plan-bottom-notice">
        <h5>
          {lang === "English"
            ? "You can go back to the “Review and Select” tab to add treatments."
            : "Vous pouvez revenir à l’étape “Révision et sélection” pour ajouter des traitements."}
        </h5>
      </div>
    </div>
  );
};

export default PlanList;
