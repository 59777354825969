import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { getRequest } from "../../API/ApiHandler";
import CircleControl from "../UI/Slider/CircleControl";
import "./ButtonSpacing.css";

const QSymptom = ({
  symptomType,
  frSymptomType,
  headingQuestion,
  frHeadingQuestion,
  questionNo,
  setSymptomLevel,
  symptomLevel,
  setSaved,
  setSaveStatus,
  readOnly = false
}) => {
  const [collapse, setCollapse] = useState(true);

  const getMinLabel = () => {
    if (lang === "English") {
      let label = titleCase(`${symptomType}`);
      return "No " + label;
    }

    let label = String(frSymptomType);

    let firstLetter = label.charAt(0).toLowerCase();

    if ("aeiou".includes(firstLetter)) {
      return "Pas d'" + label;
    }
    return "Pas de " + label;
  };

  const getMaxLabel = () => {
    if (lang === "English") {
      let label = titleCase(`${symptomType}`);
      return "Very Severe " + label;
    }
    let label = titleCase(`${frSymptomType}`) + " très sévère";

    if (frSymptomType.toLowerCase() === "raideurs") {
      label = label + "s";
    }

    return label;
  };
  const getValues = async () => {
    try {
      const { data: symptomData } = await getRequest(
        `/symptomQuestions/${symptomType}`
      );

      if (symptomData && symptomData.data) {
        const symptomQuestion = symptomData.data;
        setSymptomLevel(symptomQuestion.symptom_level);
        highlightSelected(symptomQuestion.symptom_level);
      } else {
        console.warn("symptomData or symptomData.data is undefined");
      }
    } catch (error) {
      console.error("Error in getValues:", error);
    }
  };

  const highlightSelected = (pres) => {
    const buttons = document.querySelectorAll(".StepFour-div div");
    buttons.forEach((button) => {
      if (button.getAttribute("value") === pres) {
        button.style.backgroundColor = "#10434F";
      }
    });

    setSaved(false);
    setSaveStatus("default");
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
    sessionStorage.setItem(`${symptomType}-collapse`, !collapse);
  };

  const titleCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase(0);
  };

  useEffect(() => {
    getValues();
    setCollapse(
      JSON.parse(sessionStorage.getItem(`${symptomType}-collapse`) || "true")
    );
  }, []);

  let lang = localStorage.getItem("language");

  return (
    <React.Fragment>
      <h2 style={{ fontWeight: "bold", color: "var(--primary-colour)" }}>
        {lang === "English"
          ? titleCase(`${symptomType}`)
          : titleCase(`${frSymptomType}`)}
      </h2>
      <div className="box-container-numbered collapsable">
        <div onClick={handleCollapse}>
          <p className="numbered-subheading">
            {lang === "English"
              ? `${questionNo}. ${headingQuestion}`
              : `${questionNo}. ${frHeadingQuestion}`}
            <span className="collapsed-icon">
              {collapse
                ? "▲"
                : lang === "English"
                ? "(Expand) ▼"
                : "(Développer) ▼"}
            </span>
          </p>
        </div>
        <Collapse isOpen={collapse}>
          <CircleControl
            setValue={(value) => {
              setSymptomLevel(value);
              highlightSelected(value);
            }}
            minLabel={getMinLabel()}
            maxLabel={getMaxLabel()}
            reversed={true}
            value={symptomLevel}
            forSlider="pain-level"
            disabled={readOnly}
            readOnly={readOnly}
          />
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default QSymptom;
