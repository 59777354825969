import React, { useEffect, useState } from "react";
import ButtonVals from "../ButtonVals";

const SymptomButton = ({
  sym,
  selectedSyms,
  setSelectedSyms,
  setSaved,
  setSaveStatus
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const isSelectedNow = selectedSyms.some((item) => item.name === sym.name);
    setIsSelected(isSelectedNow);
  }, [selectedSyms, sym.name]);

  const clickHandler = () => {
    if (isSelected) {
      setSelectedSyms((prev) => prev.filter((item) => item.name !== sym.name));
      setIsSelected(false);
    } else {
      setSelectedSyms((prev) => [...prev, { name: sym.name }]);
      setIsSelected(true);
    }

    setSaved(false);
    setSaveStatus("default");
  };

  let lang = localStorage.getItem("language");

  return (
    <button
      id={sym.name}
      onClick={clickHandler}
      className={`symptom-button ${isSelected ? "selected-symptom" : ""}`}
      style={
        isSelected
          ? {
              backgroundColor: "var(--primary-colour)",
              color: "white",
              borderColor: "var(--primary-colour)"
            }
          : {
              backgroundColor: "white",
              color: "var(--primary-colour)",
              borderColor: "var(--primary-colour)"
            }
      }
    >
      {lang === "English" ? sym.name : sym.fr_name}
    </button>
  );
};

export default SymptomButton;
